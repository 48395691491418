<template>
  <v-container class="my-10">
    <v-footer
      inset
      absolute
      height="75"
      style="background: #1b1b1b; border-top: 1px solid rgb(47 47 47) !important; overflow: hidden;"
      id="dashboard-core-footer"
    >
      <v-row
        no-gutters
        class="fill-height mx-0"
        align="center"
        style="max-width: 100%"
      >
        <!-- Carbon Image -->
        <v-col cols="2" class="h-100 d-flex align-center">
          <a
            target="_blank"
            href="https://www.carbonfootprint.com/"
            class="carbon-link d-flex align-center h-100 pl-2"
          >
            <v-img
              max-height="45"
              max-width="70"
              contain
              src="assets/branding/cb_neutral.png"
              alt="Carbon Neutral"
            />
          </a>
        </v-col>
  
        <!-- Policy Buttons -->
        <v-col
          cols="8"
          class="d-flex justify-center align-center h-100 policy-buttons-container"
        >
          <div class="policy-buttons-wrapper">
            <v-btn
              text
              class="text-uppercase policy-button"
              href="/#/privacy-policy"
            >
              Privacy Policy
            </v-btn>
          </div>
        </v-col>
  
        <!-- Copyright -->
        <v-col cols="2" class="d-flex justify-end align-center pr-4 h-100">
          <div class="copyright-text">
            <span class="long-text">
              &copy; {{ new Date().getFullYear() }}, made with
              <v-icon
                size="14"
                color="primary"
              >
                mdi-heart
              </v-icon>
              by
            </span>
            <span class="short-text">
              &copy; {{ new Date().getFullYear() }}
            </span>
            <a
              target="_blank"
              class="footer-link"
              href="https://www.dooh.com"
            >DOOH.com</a>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
  </template>
  
  <script>
  export default {
  name: 'DashboardCoreFooter',
  }
  </script>
  
  <style lang="scss" scoped>
  #dashboard-core-footer {
  .h-100 {
    height: 100%;
  }
  
  .carbon-link {
    &:hover {
      opacity: 0.8;
    }
  }
  
  ::v-deep .v-btn {
    font-weight: 500;
    height: 28px !important;
    min-width: auto !important;
    padding: 0 8px !important;
    letter-spacing: 0.0892857143em;
    color: grey;
    
    &:hover {
      color: var(--v-primary-base);
    }
  }
  
  .copyright-text {
    color: grey;
    white-space: nowrap;
  
    .long-text {
      display: inline;
    }
  
    .short-text {
      display: none;
    }
  }
  
  .footer-link {
    color: var(--v-primary-base);
    text-decoration: none;
    transition: opacity 0.3s;
    padding: 0px;
    padding-left: 1px;
    font-size: 0.1rem;
    
    &:hover {
      opacity: 0.8;
    }
  }
  
  .policy-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap; // Prevent wrapping by default
  }
  
  .policy-button {
    margin: 0 4px; // Add some horizontal margin
  }
  
  @media (max-width: 755px) {
    .copyright-text {
      font-size: 0.7rem;
  
      .long-text {
        display: none;
      }
  
      .short-text {
        display: inline;
      }
    }
  }
  
  @media (max-width: 512px) {
    .policy-buttons-wrapper {
      flex-direction: column;
      gap: 0px;
    }
  
    .policy-button {
      margin: 0; // Remove horizontal margins when stacked
    }
  }
  }
  </style>
  